import React, { useEffect } from "react";
import { Recipes } from "./recipes";
import Link from "next/link";
import { translate } from "react-i18nify";
import { useRouter } from "next/router";
import { Layout } from "../components";
import { useSelector, useDispatch } from "react-redux";
import { GlobalAppState } from "@goodfood/store";
import { GetRecipesActions, RecipeCard } from "@mealhaus/recipes";
import { useAuth } from "use-auth0-hooks";
import { fetchClient } from "@goodfood/lib";
import {
  GetCustomerLatestSubscriptionActions,
  GetCustomerLatestSubscriptionState,
} from "@mealhaus/customer";
import { getconfig, getImgUrl } from "@goodfood/utils";
import { routes } from "@mealhaus/navigation";
import Imgix from "react-imgix";
import lazySizes from "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import { CreateMealPlanSubscriptionSubscriptionDto } from "@mealhaus/meal-plan-subscription";

const config = getconfig();

const IndexPage = () => {
  const router = useRouter();
  const recipes = useSelector(
    (state: GlobalAppState) => state.getRecipes.recipes
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetRecipesActions.getRecipes());
  }, []);

  const { accessToken } = useAuth({
    audience: config.audience,
    scope: config.scope,
  });
  fetchClient.setAuthToken(accessToken);

  const customerLatestSubscriptionState: GetCustomerLatestSubscriptionState = useSelector(
    (state: GlobalAppState) => state.getCustomerLatestSubscription
  );

  const userHasSubscription = !!customerLatestSubscriptionState.subscription
    .length;

  if (userHasSubscription) {
    router.push(`/${routes.MENU}`);
  }

  const mealPlanSubscription: Pick<
    CreateMealPlanSubscriptionSubscriptionDto,
    "numberOfWeeks" | "numberOfRecipes" | "numberOfServings" | "mealPlanId"
  > = {
    numberOfWeeks: 1,
    numberOfRecipes: 2,
    numberOfServings: 2,
    mealPlanId: "100",
  };

  useEffect(() => {
    dispatch(
      GetCustomerLatestSubscriptionActions.getCustomerLatestSubscription()
    );
  }, [accessToken]);

  return (
    <>
      <Layout
        title="Mealhaus | Meal Kit Service"
        className="mt-0"
        marginTop={false}
      >
        <div className="container-fluid p-0 overflow-hidden">
          <div className="banner bg-light d-flex align-items-center justify-content-between p-4">
            <div className="flex-grow-1 ml-md-5">
              <div className="font-weight-500 title">
                Delicious recipes delivered to your door
              </div>
              <div className="font-weight-light text-large">
                We save you time, stress and money. We deliver fresh groceries
                to you every week
              </div>
             
              <Link
                href={{
                  pathname: `/select-meal-plan`,
                  query: { ...router.query },
                }}
              >
                <button className="btn btn-primary my-3 btn-lg text-large mt-4 d-none d-sm-inline-block">
                  {translate("homePagePage.getStarted")}
                </button>
              </Link>
              <Link
                href={{
                  pathname: `/select-meal-plan`,
                  query: { ...router.query },
                }}
              >
                <button className="btn btn-primary my-3 btn-lg text-large mt-4 w-100 d-sm-none">
                  {translate("homePagePage.getStarted")}
                </button>
              </Link>
            </div>
            <Imgix
              src={getImgUrl(`static/delivery.png`)}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              sizes="70vw"
              className="img-fluid banner-img d-none d-md-block lazyload"
            />
          </div>
          <section className="py-5 px-2">
            <div className="title font-weight-500 text-center mb-4">
              How it works
            </div>
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-4 text-center">
                  <Imgix
                    sizes="auto"
                    src={getImgUrl(`static/select_meal.svg`)}
                    attributeConfig={{
                      src: "data-src",
                      srcSet: "data-srcset",
                      sizes: "data-sizes",
                    }}
                    className="v-icon mb-4 lazyload"
                  />
                  <div className="text-large font-weight-500 text-center">
                    Choose your meals
                  </div>
                  <div className="font-weight-light text-large text-center">
                    {" "}
                    Plan your meals weeks ahead by selecting recipes from our
                    menu
                  </div>
                </div>
                <div className="col-12 col-md-4 text-center mb-4 mb-md-0">
                  <Imgix
                    sizes="auto"
                    src={getImgUrl(`static/grocery.svg`)}
                    attributeConfig={{
                      src: "data-src",
                      srcSet: "data-srcset",
                      sizes: "data-sizes",
                    }}
                    className="v-icon mb-4 lazyload"
                  />
                  <div className="text-large font-weight-500">
                    Unpack your box
                  </div>
                  <div className="font-weight-light text-large">
                    We deliver the recipes and groceries to you every week
                  </div>
                </div>
                <div className="col-12 col-md-4 text-center mb-5 mb-md-0">
                  <Imgix
                    sizes="auto"
                    src={getImgUrl(`static/cook.svg`)}
                    attributeConfig={{
                      src: "data-src",
                      srcSet: "data-srcset",
                      sizes: "data-sizes",
                    }}
                    className="v-icon mb-4 lazyload"
                  />
                  <div className="text-large font-weight-500">Cook</div>
                  <div className="font-weight-light text-large">
                    Prepare your meals with our time saving and easy-to-follow
                    recipes
                  </div>
                </div>
                <div className="col-12 text-center mt-4">
                  <Link
                    href={{
                      pathname: `/select-meal-plan`,
                      query: { ...router.query },
                    }}
                  >
                    <button className="btn btn-primary btn-lg text-large mt-4 w-auto">
                      Choose your plan
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section className="py-5 px-2 bg-light">
            <div className="container">
              <div className="row">
                <div className="col-12 mb-4 mb-md-0">
                  <div className="text-center font-weight-500 title mb-4">Let's get you started</div>
                  <div className="text-center font-weight-light">Plan your first week</div>
                  <div className="text-center font-weight-light">Try any two of our recipes for just <span className="font-weight-500">N2000</span></div>
                  <div className="font-weight-500 text-center">Free delivery</div>
                  <div className="text-center">
                    <Link
                      href={{
                        pathname: `/address`,
                        query: { ...router.query, ...mealPlanSubscription },
                      }}
                    >

                      <button className="btn btn-primary btn-lg mt-4 text-large w-auto">
                        Try it out
                      </button>
                    </Link>
                    
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="py-5 px-2">
            <div className="title font-weight-500 text-center mb-5">
              Our menu
            </div>
            <div className="container">
              <div className="row mb-md-4">
                {recipes.map((recipe) => {
                  return (
                    <div className="col-lg-4 mb-4 mb-lg-0" key={recipe.id}>
                      <RecipeCard recipe={recipe} includeFooter={false} />
                    </div>
                  );
                })}
              </div>
              <div className="text-center">
                <Link
                  href={{
                    pathname: `/recipes`,
                    query: { ...router.query },
                  }}
                >
                  <button className="btn btn-primary btn-lg mt-4 text-large w-auto">
                    See our menu
                  </button>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </Layout>

      <style jsx>
        {`
          .banner {
            max-height: 650px;
          }
          .banner-img {
            max-width: 70%;
            margin-right: -1.875rem;
          }
          .title {
            font-size: 2rem;
          }
          :global(.v-icon) {
            max-width: 6rem;
          }
          .text-decoration-underline {
            text-decoration: underline;
          }
        `}
      </style>
    </>
  );
};

export default IndexPage;
